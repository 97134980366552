const REACT_PROTOCOL =
  window._env_.REACT_APP_BACKEND_PROTOCOL ||
  process.env.REACT_APP_BACKEND_PROTOCOL

const REACT_HOST =
  window._env_.REACT_APP_BACKEND_HOST || process.env.REACT_APP_BACKEND_HOST

const REACT_PORT =
  window._env_.REACT_APP_BACKEND_PORT || process.env.REACT_APP_BACKEND_PORT

const BACKEND = `${REACT_PROTOCOL}://${REACT_HOST}:${REACT_PORT}/api`

export { BACKEND }
